/**
 * Transitions the parameter element from the state of open to closed.
 * @param {Element}
 */
export function collapseDrawer(element) {
    const el = element;
    const sectionHeight = el.scrollHeight;

    const elementTransition = el.style.transition;
    el.style.transition = '';

    requestAnimationFrame(() => {
        el.style.height = `${sectionHeight}px`;
        el.style.transition = elementTransition;

        requestAnimationFrame(() => {
            el.style.height = `${0}px`;
        });
    });
}
/**
 * Transitions the parameter element from the state of closed to open.
 * @param {Element}
 */
export function expandDrawer(element) {
    const el = element;
    const sectionHeight = element.scrollHeight;

    el.style.height = `${sectionHeight}px`;
}
